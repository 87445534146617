import { useResetPeriodicNotification } from '@/hooks/use-periodic-notification';
import { NOT_READY_YET_TOUR_KEY } from '@/modules/advisor/homepage/hooks/use-you-are-hidden-reminder';
import { useAuth0 } from '@auth0/auth0-react';
import { startLogout } from '@context/user/user-account.actions';
import { LOGOUT_LS_KEY } from '@context/user/utils';
import useUserAccount from '../use-user-account/use-user-account';
import { usePostHog } from 'posthog-js/react';

export const useLogout = () => {
  const { logout: logoutAction } = useAuth0();
  const {
    state: { userId },
  } = useUserAccount();
  const { dispatch } = useUserAccount();
  const postHog = usePostHog();

  const { reset: resetNotReadyYetNotification } = useResetPeriodicNotification(NOT_READY_YET_TOUR_KEY, userId || '');

  // Creates 'storage' event with LOGOUT_LS_KEY on window object
  // which will be emitted in other browser tabs
  const notifyTabsAboutLogoutEvent = () => {
    localStorage.setItem(LOGOUT_LS_KEY, Date.now().toString());
  };

  const logout = () => {
    dispatch(startLogout());

    postHog.reset();
    resetNotReadyYetNotification();
    if (process.env.REACT_APP_PENDO_API_KEY && pendo && typeof pendo.clearSession === 'function') {
      pendo.clearSession();
    }

    logoutAction({ logoutParams: { returnTo: window.location.origin } });

    notifyTabsAboutLogoutEvent();
  };

  return { logout };
};
