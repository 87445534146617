import { V2Routes } from '@/v2-router/const/routes';
import { AccountStatus } from '@context/user/user-account.context';
import { getHighestSubscriptionProduct } from '@domain/accounts/subscription-products';
import { useGoogleTagManager } from '@utils/hooks/use-google-tag-manager/use-google-tag-manager';
import { useHotJar } from '@utils/hooks/use-hotjar/use-hotjar';
import { usePendo } from '@utils/hooks/use-pendo/use-pendo';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isActiveFounder } from './abilities/ability/abilities/utils/is-active-founder';
import { isActiveInvestor } from './abilities/ability/abilities/utils/is-active-investor';
import { isActiveNed } from './abilities/ability/abilities/utils/is-active-ned';
import { DebugComponent } from './debug-component';
import { SplashScreenPage } from './pages/splash-screen/splash-screen.page';
import { isAppEnv } from './utils/fns/is-app-env';
import { Routes } from './v2-router';
import { usePostHog } from 'posthog-js/react';

import './app.less';

export const shouldRenderSplash = (status: AccountStatus, isAuthorized: boolean) =>
  [AccountStatus.AWAITING_ADMIN_ACCEPTANCE, AccountStatus.BAN, AccountStatus.INACTIVE].includes(status) && isAuthorized;

export const App = () => {
  const location = useLocation();

  usePendo();
  useHotJar();
  const postHog = usePostHog();

  const { isAvailable: isGtmAvailable, dataLayer: gtmDataLayer } = useGoogleTagManager();

  const {
    state: {
      status,
      isAuthorized,
      products,
      email,
      userId: id,
      name: fullName,
      businessName: companyName,
      countryOfResidence,
      integrations: {
        stripe: { customerId },
      },
    },
  } = useUserAccount();

  const isAdminLoginAsUser = location.pathname === V2Routes.ADMIN_LOGIN_AS_USER;

  const { type: subscriptionType } = getHighestSubscriptionProduct(products) || {};

  useEffect(() => {
    if (process.env.REACT_APP_PENDO_API_KEY && isAuthorized && !isAdminLoginAsUser) {
      pendo.initialize();
      pendo.updateOptions({
        visitor: {
          id,
          email,
          fullName,
          companyName,
          isNed: isActiveNed(products),
          isFounder: isActiveFounder(products),
          isInvestor: isActiveInvestor(products),
          countryofresidence: countryOfResidence,
          subscriptiontype: subscriptionType,
        },
      });
    }
  }, [isAuthorized, fullName, companyName, products, countryOfResidence, email, id]);

  useEffect(() => {
    if (isGtmAvailable && gtmDataLayer && customerId && !isAdminLoginAsUser) {
      gtmDataLayer.push({ event: 'start_pw', pw_customer_id: customerId });
    }
  }, [customerId, gtmDataLayer]);

  useEffect(() => {
    if (process.env.REACT_APP_PUBLIC_POSTHOG_KEY && id && email) {
      postHog.identify(id, {
        email,
      });
    }
  }, [id, email]);

  if (shouldRenderSplash(status, isAuthorized)) {
    return <SplashScreenPage />;
  }

  return (
    <>
      {isAppEnv('local') && <DebugComponent />}
      <Routes />
    </>
  );
};
