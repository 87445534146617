import { AppThemeProvider } from '@/context/theme/app-theme.provider';
import { MaintenanceModeWrapper } from '@/pages/utility/maintenance/maintenance-mode-wrapper';
import { UserAbilityProvider } from '@abilities/context/context';
import { AppAuth0Provider } from '@context/auth0/auth0-provider';
import { LocaleProvider } from '@context/locale/locale.provider';
import { MessagingProvider } from '@context/messaging/messaging.provider';
import { NotificationsStateProvider } from '@context/notifications/notifications.provider';
import { AppQueryClientProvider } from '@context/query-client/query-client-provider';
import { UserAccountProvider } from '@context/user/user-account.provider';
import { MetricsStateProvider } from '@pages/content/parts/metrics/context/metrics.provider';
import { FundingRoundsStateProvider } from '@pages/content/pulse/founder/parts/progress/parts/context/funding-rounds.provider';
import { RoleManagementStateProvider } from '@pages/content/roles/management/context/management.provider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppLoggerErrorBoundary } from '@utils/honeybadger/error-boundary';
import type { ReactNode } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import { TourProvider } from '@utils/tour/tour.provider';
import { AppVersionChecker } from '../app-version/app-version-checker';
import { AppPostHogProvider } from '@context/posthog/posthog-provider';

interface Props {
  children: ReactNode;
}

export const AppProvider = ({ children }: Props) => (
  <MaintenanceModeWrapper>
    <Router>
      <AppThemeProvider>
        <AppLoggerErrorBoundary>
          <AppPostHogProvider>
            <AppAuth0Provider>
              <AppQueryClientProvider>
                <LocaleProvider>
                  <UserAccountProvider>
                    <UserAbilityProvider>
                      <MessagingProvider>
                        <TourProvider>
                          <NotificationsStateProvider>
                            <FundingRoundsStateProvider>
                              <RoleManagementStateProvider>
                                <MetricsStateProvider>
                                  <CookiesProvider>
                                    <AppVersionChecker />
                                    {children}
                                  </CookiesProvider>
                                </MetricsStateProvider>
                              </RoleManagementStateProvider>
                            </FundingRoundsStateProvider>
                          </NotificationsStateProvider>
                        </TourProvider>
                      </MessagingProvider>
                    </UserAbilityProvider>
                  </UserAccountProvider>
                </LocaleProvider>
                <ReactQueryDevtools />
              </AppQueryClientProvider>
            </AppAuth0Provider>
          </AppPostHogProvider>
        </AppLoggerErrorBoundary>
      </AppThemeProvider>
    </Router>
  </MaintenanceModeWrapper>
);
