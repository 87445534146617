import type { ReactNode } from 'react';
import { PostHogProvider } from 'posthog-js/react';
import type { PostHogConfig } from 'posthog-js';

export const AppPostHogProvider = ({ children }: { children: ReactNode }) => {
  const options: Partial<PostHogConfig> = {
    api_host: 'https://eu.i.posthog.com',
  };

  return (
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      {children}
    </PostHogProvider>
  );
};
