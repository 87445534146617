import ArrowLeftRight from '@assets/v2-icons/arrow-left-right.svg?react';
import ArrowLeft from '@assets/v2-icons/arrow-left.svg?react';
import ArrowRight from '@assets/v2-icons/arrow-right.svg?react';
import BankFilled from '@assets/v2-icons/bank-filled.svg?react';
import Bank from '@assets/v2-icons/bank.svg?react';
import BarChartLine from '@assets/v2-icons/bar-chart-line.svg?react';
import BarChart from '@assets/v2-icons/bar-chart.svg?react';
import Bell from '@assets/v2-icons/bell.svg?react';
import BoxArrowUpRight from '@assets/v2-icons/box-arrow-up-right.svg?react';
import Box from '@assets/v2-icons/box.svg?react';
import Briefcase from '@assets/v2-icons/briefcase.svg?react';
import Building from '@assets/v2-icons/building.svg?react';
import Bullseye from '@assets/v2-icons/bullseye.svg?react';
import Calendar from '@assets/v2-icons/calendar.svg?react';
import CashCoin from '@assets/v2-icons/cash-coin.svg?react';
import Cash from '@assets/v2-icons/cash.svg?react';
import ChatLeftDots from '@assets/v2-icons/chat-left-dots.svg?react';
import CheckCircle from '@assets/v2-icons/check-circle.svg?react';
import Check from '@assets/v2-icons/check.svg?react';
import CheckboxIcon from '@assets/v2-icons/checkbox-icon.svg?react';
import ChevronDown from '@assets/v2-icons/chevron-down.svg?react';
import ChevronLeft from '@assets/v2-icons/chevron-left.svg?react';
import ChevronRight from '@assets/v2-icons/chevron-right.svg?react';
import ChevronUp from '@assets/v2-icons/chevron-up.svg?react';
import Clock from '@assets/v2-icons/clock.svg?react';
import CloseInCircle from '@assets/v2-icons/close-in-circle.svg?react';
import Close from '@assets/v2-icons/close.svg?react';
import Connection from '@assets/v2-icons/connection.svg?react';
import Cpu from '@assets/v2-icons/cpu.svg?react';
import CvDocument from '@assets/v2-icons/cv-document.svg?react';
import Document from '@assets/v2-icons/document.svg?react';
import Download from '@assets/v2-icons/download.svg?react';
import Edit from '@assets/v2-icons/edit.svg?react';
import Envelope from '@assets/v2-icons/envelope.svg?react';
import Eye from '@assets/v2-icons/eye.svg?react';
import FileText from '@assets/v2-icons/file-text.svg?react';
import Funnel from '@assets/v2-icons/funnel.svg?react';
import GraphUpArrow from '@assets/v2-icons/graph-up-arrow.svg?react';
import GraphUp from '@assets/v2-icons/graph-up.svg?react';
import Grid from '@assets/v2-icons/grid.svg?react';
import HappyFaceIcon from '@assets/v2-icons/happy-face-icon.svg?react';
import HourglassEmpty from '@assets/v2-icons/hourglass-empty.svg?react';
import HourglassFull from '@assets/v2-icons/hourglass-full.svg?react';
import HourglassHalf from '@assets/v2-icons/hourglass-half.svg?react';
import House from '@assets/v2-icons/house.svg?react';
import IndifferentFaceIcon from '@assets/v2-icons/indifferent-face-icon.svg?react';
import InfoCircle from '@assets/v2-icons/info-circle.svg?react';
import Kebab from '@assets/v2-icons/kebab.svg?react';
import LinkedIn from '@assets/v2-icons/linkedin.svg?react';
import X from '@assets/v2-icons/x.svg?react';
import Loading from '@assets/v2-icons/loading.svg?react';
import Lock from '@assets/v2-icons/lock.svg?react';
import Megaphone from '@assets/v2-icons/megaphone.svg?react';
import Menu from '@assets/v2-icons/menu.svg?react';
import Mortarboard from '@assets/v2-icons/mortarboard.svg?react';
import NeutralFaceIcon from '@assets/v2-icons/neutral-face-icon.svg?react';
import People from '@assets/v2-icons/people.svg?react';
import PersonDash from '@assets/v2-icons/person-dash.svg?react';
import PersonPlus from '@assets/v2-icons/person-plus.svg?react';
import Person from '@assets/v2-icons/person.svg?react';
import Play from '@assets/v2-icons/play.svg?react';
import Player from '@assets/v2-icons/player.svg?react';
import Plus from '@assets/v2-icons/plus.svg?react';
import Puzzle from '@assets/v2-icons/puzzle.svg?react';
import QuestionDiamond from '@assets/v2-icons/question-diamond.svg?react';
import SadFaceIcon from '@assets/v2-icons/sad-face-icon.svg?react';
import Search from '@assets/v2-icons/search.svg?react';
import Shop from '@assets/v2-icons/shop.svg?react';
import Sorting from '@assets/v2-icons/sorting.svg?react';
import Stars from '@assets/v2-icons/stars.svg?react';
import Trash from '@assets/v2-icons/trash.svg?react';

export const V2_ICONS_CONFIG = [
  { name: 'arrow-left', component: ArrowLeft },
  { name: 'arrow-left-right', component: ArrowLeftRight },
  { name: 'arrow-right', component: ArrowRight },
  { name: 'bank', component: Bank },
  { name: 'bank-filled', component: BankFilled },
  { name: 'bar-chart', component: BarChart },
  { name: 'bar-chart-line', component: BarChartLine },
  { name: 'bell', component: Bell },
  { name: 'bullseye', component: Bullseye },
  { name: 'check', component: Check },
  { name: 'check-circle', component: CheckCircle },
  { name: 'chevron-left', component: ChevronLeft },
  { name: 'chevron-right', component: ChevronRight },
  { name: 'chevron-up', component: ChevronUp },
  { name: 'chevron-down', component: ChevronDown },
  { name: 'close', component: Close },
  { name: 'close-in-circle', component: CloseInCircle },
  { name: 'download', component: Download },
  { name: 'envelope', component: Envelope },
  { name: 'eye', component: Eye },
  { name: 'file-text', component: FileText },
  { name: 'graph-up', component: GraphUp },
  { name: 'graph-up-arrow', component: GraphUpArrow },
  { name: 'house', component: House },
  { name: 'info-circle', component: InfoCircle },
  { name: 'menu', component: Menu },
  { name: 'people', component: People },
  { name: 'person', component: Person },
  { name: 'question-diamond', component: QuestionDiamond },
  { name: 'shop', component: Shop },
  { name: 'stars', component: Stars },
  { name: 'trash', component: Trash },
  { name: 'checkbox-icon', component: CheckboxIcon },
  { name: 'cash-coin', component: CashCoin },
  { name: 'box-arrow-up-right', component: BoxArrowUpRight },
  { name: 'chat-left-dots', component: ChatLeftDots },
  { name: 'linkedin', component: LinkedIn },
  { name: 'x', component: X },
  { name: 'document', component: Document },
  { name: 'grid', component: Grid },
  { name: 'box', component: Box },
  { name: 'megaphone', component: Megaphone },
  { name: 'cpu', component: Cpu },
  { name: 'sorting', component: Sorting },
  { name: 'puzzle', component: Puzzle },
  { name: 'search', component: Search },
  { name: 'funnel', component: Funnel },
  { name: 'kebab', component: Kebab },
  { name: 'connection', component: Connection },
  { name: 'building', component: Building },
  { name: 'clock', component: Clock },
  { name: 'person-dash', component: PersonDash },
  { name: 'person-plus', component: PersonPlus },
  { name: 'cash', component: Cash },
  { name: 'edit', component: Edit },
  { name: 'plus', component: Plus },
  { name: 'cv-document', component: CvDocument },
  { name: 'lock', component: Lock },
  { name: 'mortarboard', component: Mortarboard },
  { name: 'briefcase', component: Briefcase },
  { name: 'calendar', component: Calendar },
  { name: 'player', component: Player },
  { name: 'play', component: Play },
  { name: 'hourglass-empty', component: HourglassEmpty },
  { name: 'hourglass-half', component: HourglassHalf },
  { name: 'hourglass-full', component: HourglassFull },
  { name: 'indifferent-face', component: IndifferentFaceIcon },
  { name: 'sad-face', component: SadFaceIcon },
  { name: 'neutral-face', component: NeutralFaceIcon },
  { name: 'happy-face', component: HappyFaceIcon },
  { name: 'loading', component: Loading },
] as const;

export type V2IconName = (typeof V2_ICONS_CONFIG)[number]['name'];
